<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-dialog
    persistent
    v-model="dialog"
    max-width="660px"
    transition="dialog-transition"
  >
    <template>
      <v-card>
        <v-toolbar color="primary" dark>Lead Relationship</v-toolbar>
        <v-card-text class="d-flex mt-2">
          <v-autocomplete
            class="mx-1 my-3"
            :label="`Select by ${getCoordinatorN}`"
            :items="workers"
            v-model="doctorUuid"
            item-text="fullname"
            item-value="uuid"
            style="max-width: 300px"
            prepend-inner-icon="mdi-account-search"
            clearable
            hide-details
            outlined
            dense
          ></v-autocomplete>
          <v-select
            class="mx-1 my-3"
            :disabled="doctorUuid == '' || doctorUuid == null"
            v-model="ocupationId"
            label="Occupations"
            :items="ocupations"
            item-text="name"
            item-value="id"
            :loading="loading"
            hide-details
            outlined
            dense
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="cancel(false)">Close</v-btn>
          <v-btn
            :disabled="
              doctorUuid == '' ||
              doctorUuid == null ||
              ocupationId == null ||
              loadingAction
            "
            :loading="loadingAction"
            color="primary"
            text
            @click="confirm()"
            >Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import { getAPI } from "@/api/axios-base";
import { notifyError, notifySuccess } from "@/components/Notification";
import Vue from "vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default Vue.extend({
  props: {
    leadUuid: String,
    leadUuids: [],
    color: String,
    asignDialog:Boolean
  },
  name: "crm-asign-to",
  data() {
    return {
      dialog: false,
      doctorUuid: "",
      contador: 0,
      ocupationId: null,
      ocupations: [],
      loading: false,

      loadingAction: false,
    };
  },
  watch: {
    doctorUuid(val) {
      if (val != "" && val != null) {
        const worker = this.workers.filter((w) => w.uuid == val)[0];
        this.ocupationId = worker.ocupation ? worker.ocupation.id : null;
      } else {
        this.ocupationId = null;
      }
    },
    asignDialog(val){
      this.dialog=val
    },
    contador(val) {
      if (val == this.leadUuids.length) {
        notifySuccess(this.$t("medicForm.notificationPatientTransfer"));
        this.cancel(true);
      
      }
    },
  },

  computed: {
    ...mapGetters(["getCoordinatorN"]),
    ...mapState("crmEmployeeModule", ["workers"]),
  },
  mounted() {
    if (this.workers.length == 0) {
      this.actGetEmployees();
    }
    this.listOcupations();
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGetEmployees"]),
    ...mapActions("crmConfigurationsModule", [
      "actAsignToLead",
      "actMultiAsignToLead",
    ]),

    cancel(value) {
      this.dialog = false;
      this.doctorUuid = "";
      this.contador = 0;
      this.ocupationId = null;
      this.loadingAction = false;
      this.$emit("assigned",value);
    },

    async listOcupations() {
      this.ocupations = (await getAPI(`/ocupation-nom/list`)).data;
    },

    async confirm() {
      if (this.leadUuids == undefined || this.leadUuids.length == 0) {
        this.loadingAction = true;
        getAPI
          .post("/patient/createRelation", {
            leadUuid: this.leadUuid,
            employeeUuid: this.doctorUuid,
            ocupationId: this.ocupationId,
          })
          .then(() => {
            
            notifySuccess(this.$t("Realtionship has been created"));
            this.cancel(true);
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();
            this.loadingAction = false;

            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      } else {
        const leadTransfer = this.leadUuids.map((lead) => lead.uuid);
        let body = {
          leadUuids: leadTransfer,
          employeeUuid: this.doctorUuid,
          ocupationId: this.ocupationId,
        };
        this.loadingAction = true;
        getAPI
          .post("/patient/createMultipleRelations", body)
          .then(() => {
            notifySuccess(this.$t("Realtionship has been created"));
            this.cancel(true);
            
          })
          .catch((error) => {
            let mess = error.response.data.message.toString();
            this.loadingAction = false;

            notifyError(error.response.data, `An error occurred: ${mess}`);
          });
      }
    },
  },
});
</script>
<style lang="scss" scope>
.swipri {
  margin-top: -1px !important;
}
</style>
